import React from "react";
import styled from "styled-components";
import { Container } from "../../components/Layouts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faXTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const HomeFooter = ({ children }) => {
  function scrollToSelector(selector) {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <FOOTER>
      {children}
      <Container>
        <nav>
          <div className="logo">
            <img src="/new-npay-logo.png" alt="NPay Logo" />
            <h4>NPay</h4>
          </div>
          <ul style={{width:"70%", justifyContent:"end"}}>
            <li>
              <Link to="/" onClick={() => scrollToSelector("#hero")}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => scrollToSelector("#features")}>
                Features
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => scrollToSelector("#instructions")}>
                Instructions
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => scrollToSelector("#services")}>
                Services
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => scrollToSelector("#contact")}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <hr />
        <div className="socials">
          <div className="copyright">
            <p>
              <FontAwesomeIcon icon={faCopyright} className="copyright-icon" />
              2024 All rights reserved
            </p>
          </div>
          <div className="social-icons">
            <a href="https://web.facebook.com/people/npayng/61564185981946/?mibextid=ZbWKwL">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://x.com/npayng?t=Q4zEAfbQ7sLJOMMcQzOO8w&s=09">
              {/* <FontAwesomeIcon icon={faTwitter} /> */}
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="https://www.instagram.com/npay.ng?igsh=MTlmbmcxODd1dHd3Yw==">
              <FontAwesomeIcon icon={faInstagram} />
              
            </a>
            <a href="">
            <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
        </div>
      </Container>
    </FOOTER>
  );
};

const FOOTER = styled.footer`
  margin-top: 30px;
  padding-top: 30px;
  background-color: var(--background-dark);
  color: white;

  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }

    h4 {
      color: var(--primary-npay);
    }
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap;

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-inline: 0.5rem;

        a {
          color: white;
          transition: color 0.2s ease-in;
          text-decoration: none;

          &:hover {
            color: var(--primary-npay);
          }
        }
      }
    }
  }

  hr {
    margin-bottom: 1rem;
    border-color: var(--primary-npay);
  }

  .socials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
    padding-bottom:20px;

    .copyright {
      text-align: left;
      font-size: var(--text-paragraph);
    }

    .social-icons {
      text-align: right;

      a {
        color: white;
        font-size: var(--text-paragraph);
        margin-left: 0.75rem;

        &:hover {
          color: var(--primary-npay);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .socials {
      grid-template-columns: 1fr;
      text-align: center;

      .copyright {
        text-align: center;
        margin-bottom: 1rem;
      }

      .social-icons {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 480px) {
    nav {
      flex-direction: column;

      ul {
        flex-direction: column;
        align-items: center;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
`;

export default HomeFooter;
