export const GETSUBSREQUEST='GETSUBSREQUEST';
export const GETSUBSSUCESS='GETSUBSSUCESS';
export const GETSUBSFAILURE='GETSUBSFAILURE';

export const ADDSUBREQUEST = "ADDSUBREQUEST";
export const ADDSUBFAILURE = "ADDSUBFAILURE";
export const ADDSUBSUCCESS = "ADDSUBSUCCESS";

export const DELETESUBREQUEST = "DELETESUBREQUEST";
export const DELETESUBFAILURE = "DELETESUBFAILURE";
export const DELETESUBSUCCESS = "DELETESUBSUCCESS";

export const EDITSUBREQUEST = "EDITSUBREQUEST";
export const EDITSUBFAILURE = "EDITSUBFAILURE";
export const EDITSUBSUCCESS = "EDITSUBSUCCESS";